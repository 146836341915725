import api from './api';

const candidateHandler = {
    update(candidate) {
        return api
            .patch('/candidate', {
                workTypes: candidate.workTypes,
                jobPreferences: candidate.jobPreferences,
                cities: candidate.cities,
                companySizes: candidate.companySizes,
                languages: candidate.languages,
                status: candidate.status,
                address: candidate.address,
                salaryExpectation: candidate.salaryExpectation,
                jobCategories: candidate.jobCategories,
                onboarding: candidate.onboarding
            })
            .then((res) => {
                return res.data;
            });
    },

    addJobWanted(key, level) {
        return api
            .post('/jobWanted', {
                key: key,
                level: level
            })
            .then((res) => {
                return res.data;
            });
    },
    openForWork(value) {
        return api
            .post('/candidate/open-for-work', {
                openForWork: value
            })
            .then((res) => {
                return res.data;
            });
    },
    mentor(value) {
        return api
            .post('/candidate/mentor', {
                mentor: value
            })
            .then((res) => {
                return res.data;
            });
    },
    submitProfile() {
        return api.post('/candidate/submit-profile', {}).then((res) => {
            return res.data;
        });
    },
    updateJobWanted(id, key, level, salary) {
        return api
            .patch(`/jobWanted/${id}`, {
                key: key,
                level: level,
                salary: salary
            })
            .then((res) => {
                return res.data;
            });
    },

    searchMatch(status) {
        return api
            .post('/candidate/match/search', {
                status: status
            })
            .then((res) => {
                return res.data;
            });
    },

    saveMentor(isMentor, skillIDs, jobTitle, mentorLevel) {
        return api
            .post('/candidate/mentor/save', {
                isMentor: isMentor,
                skillIDs: skillIDs,
                jobTitle: jobTitle,
                mentorLevel: mentorLevel
            })
            .then((res) => {
                return res.data;
            });
    },

    acceptMatch(matchId) {
        return api
            .post('/candidate/match/accept', {
                matchId: matchId
            })
            .then((res) => {
                return res.data;
            });
    },

    updateMatch(matchId, status) {
        return api
            .post('/candidate/match/update', {
                matchId: matchId,
                status: status
            })
            .then((res) => {
                return res.data;
            });
    },

    saveMatch(matchId) {
        return api
            .post('/candidate/match/save', {
                matchId: matchId
            })
            .then((res) => {
                return res.data;
            });
    },

    syncLinkedin(url) {
        return api
            .post('/candidate/syncLinkedin', {
                url: url
            })
            .then((res) => {
                return res.data;
            });
    },

    deleteJobWantedByKey(job) {
        return api
            .post(`/jobWanted/delete`, {
                key: job.key
            })
            .then((res) => {
                return res.data;
            });
    },

    setMentorCity(city) {
        return api
            .post('/candidate/city/mentor', {
                city: city
            })
            .then((res) => {
                return res.data;
            });
    },

    getMatch(id) {
        return api.get(`/candidate/match/${id}`).then((res) => {
            return res.data;
        });
    },

    archiveMatch(id) {
        return api
            .post(`/candidate/match/archive`, {
                matchId: id
            })
            .then((res) => {
                return res.data;
            });
    }
};

export default candidateHandler;
